import type { Alignment } from '../common';

interface PositionProps {
  verticalAlignment: string;
  horizontalAlignment: string;
}

export const getPosition = ({ vertical, horizontal }: Alignment): PositionProps => {
  const floatRight = horizontal === 'right' ? 'flex-end' : 'center';
  const horizontalAlignment = horizontal === 'left' ? 'flex-start' : floatRight;

  const floatTop = vertical === 'top' ? 'flex-start' : 'center';
  const verticalAlignment = vertical === 'bottom' ? 'flex-end' : floatTop;

  return {
    verticalAlignment,
    horizontalAlignment,
  };
};
