import { EditorialBannerLayoutEnum } from '@ori/graphql-schema';

export const LINK_TYPE_PRODUCT = 'Product';

export const LAYOUTS_WITH_BUTTON_INSIDE_ON_MOBILE = [
  EditorialBannerLayoutEnum.FourMedia,
  EditorialBannerLayoutEnum.OneMediaParagraphText,
  EditorialBannerLayoutEnum.ThreeMediaRightBottomText,
  EditorialBannerLayoutEnum.TwoMedia,
];

export enum OverlayPosition {
  Left = 'left',
  Right = 'right',
}

export const PAGE_ID_PATH = 'campaigns/editorials';

/**
 * User id for anonymous user.
 */
export const NOT_LOGGED_IN_USER_ID = -1;

export const YOUTUBE_MEDIA_URL = 'https://www.youtube.com/embed/';
export const YOUKU_MEDIA_URL = 'https://player.youku.com/embed/';

export const APP_NAME = 'editorial-page';
export const APPLICATION_NAME = `@ori/${APP_NAME}`;
